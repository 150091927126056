// Modo claro
$primary-light-blue: #009fe3;
$primary-light-blue-80: #33b2e9;
$primary-light-blue-100: #e3efff;
$primary-blue: #0069e3;
$primary-blue-alternative: #10a5e5;
$primary-blue-alternative-dark: #039be5;
$primary-medium-grey: #7b7a7a;
$primary-aqua: #40ffb7;
$primary-aqua-light: #70ffc9;
$primary-black: #111111;
$primary-purple-plus: #4f32ff;
$primary-blue-alternative-2: #2b52dd;

$complementary-light-grey: #c9c9c9;
$complementary-dark-grey: #3c3c3b;
$complementary-medium-grey: #727272;
$complementary-grey: #aaaaaa;

$complementary-soft-grey: #e5e5e5;
$background-alternative: #f5f5f5;

$background-soft-grey: #fcfcfc;
$border-soft-grey: #e7e7e7;
$border-advance-grey: #f4f4f4;

$error: #ff1d1d;

$copyright-text: #aeaeae;

$border-inputs: #c4c4c4;

$menu-background: #f7f7f7;
$chat-status-conected: #3bcd23;
$chat-status-inactive: #efc351;
$chat-status-disconected: #727272;

$color-text-alternative: #869aa9;
$color-text-black: #161616;

$primary-white: #ffffff;

// Modo oscuro
$background-dark: #121212;
$primary-dark: #1e1e1e;
$primary-black: #000000;
$primary-blue-light: #ADD8E6; // Azul claro para detalles
$border-dark-grey: #1e1e1e;
$text-light: #f5f5f5;
$input-dark: #2b2b2b; // Fondo de input en modo oscuro
$text-muted: #9e9e9e; // Texto gris claro
$accent-light-blue: #33b2e9; // Azul claro para resaltar
$border-light: #3c3c3b; // Bordes ligeros en modo oscuro
$background-input-dark: #292929; // Fondo de inputs en modo oscuro
$light-gray: #9e9e9e;
