.mpc-container__first-row--input-post-content {
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
		border-color: #e7e7e7 !important;
	}
}

.mpc-container__first-row--input-post-content {
	.mat-mdc-text-field-wrapper {
		width: 1021.11px;
		height: 52px;
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 16px;
		color: #7b7a7a;
	}
}

.input-search {
	.mat-mdc-text-field-wrapper {
		height: 49px !important;
	}

	.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
		background: #e5e5e5;
	}
}

.input-search {
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
		border-color: white !important;
	}
}

.input {
	.mat-mdc-text-field-wrapper {
		height: 33.43px !important;
	}
}

.input {
	.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
		padding-top: 8px !important;
		padding-bottom: 8px !important;
	}
}

.input {
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
		border-color: #e5e5e5 !important;
	}
}

.input {
	.mat-mdc-form-field-icon-suffix {
		margin-top: -22px !important;
		margin-right: 10px !important;
	}
}
