@use 'colors' as colors;

.mdc-button {
	border-radius: 5px !important;
}

.mat-mdc-button-persistent-ripple::before {
	background-color: transparent !important;
}

.lg-button,
.rg-button {
	font-weight: var(--mdc-typography-button-font-weight, 500);
}

.md-button,
.sm-button {
	font-weight: var(--mdc-typography-button-font-weight, 600);
}

%primary-button-hover {
	background-color: colors.$primary-blue-alternative-dark !important;
}

%accent-button-hover {
	background-color: colors.$primary-aqua-light !important;
	color: colors.$complementary-medium-grey !important;
}

%primary-stroked-button-hover {
	border-color: colors.$primary-light-blue-80 !important;
	color: colors.$primary-light-blue-80 !important;
}

%accent-stroked-button-hover {
	border-color: colors.$primary-aqua-light !important;
}

@mixin border-color-stroked-button($color) {
	@if $color == 'primary' {
		border-color: colors.$primary-light-blue;
		color: colors.$primary-light-blue;
	} @else {
		border-color: colors.$primary-aqua;
		color: colors.$complementary-dark-grey;
	}
}

@mixin apply-hover-color($classButtons...) {
	$buttonColors: (primary, accent);

	@each $classButton in $classButtons {
		@each $color in $buttonColors {
			@if $classButton == 'mat-stroked-button' {
				[#{$classButton}].mat-#{$color}:not([disabled='true']) {
					@include border-color-stroked-button($color);
				}
			}

			[#{$classButton}].mat-#{$color}:not([disabled='true']):hover {
				@if $classButton == 'mat-stroked-button' {
					@extend %#{$color}-stroked-button-hover;
				} @else {
					@extend %#{$color}-button-hover;
				}
			}
		}
	}
}

@mixin apply-style-basic($buttons...) {
	@each $button in $buttons {
		[#{$button}]:is(.lg-button, .rg-button, .md-button, .sm-button) {
			height: auto;
		}

		[#{$button}].lg-button {
			font-size: 16px;
			padding: 16px 60px;
			&.is-loading {
				width: 187px;
				height: 56px;
			}
		}

		[#{$button}].rg-button {
			font-size: 14px;
			padding: 13px 50px;
			&.is-loading {
				min-width: 160px;
				min-height: 48px;
			}
		}

		[#{$button}].md-button {
			font-size: 14px;
			padding: 8px 30px;
			font-weight: 600;
			&.is-loading {
				min-width: 120px;
				min-height: 36px;
			}
		}

		[#{$button}].sm-button {
			font-size: 12px;
			padding: 4px 20px;
			font-weight: 600;
			&.is-loading {
				min-width: 90px;
				min-height: 24px;
			}
		}
	}
}
@include apply-hover-color(mat-raised-button, mat-flat-button, mat-stroked-button);
@include apply-style-basic(mat-raised-button, mat-flat-button, mat-stroked-button);
