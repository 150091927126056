.mp-content__second--post-text-content {
	.mat-mdc-text-field-wrapper {
		height: 85px;
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 16px;
		color: #92929d;
	}
}

.mp-content__second--post-text-content {
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
		border-color: #ffff !important;
	}

	.mat-mdc-form-field-subscript-wrapper {
		margin-top: -20px;
	}

	.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
	.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
		height: 57px;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):not(.mdc-text-field--invalid)
		:is(.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing) {
		border-color: white !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):is(.mdc-text-field--focused)
		:is(.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing) {
		border-color: white !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):is(.mdc-text-field--focused) {
		box-shadow: none !important;
	}
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.7) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
	border-radius: var(--mdc-dialog-container-shape, var(--mdc-shape-medium, 15px)) !important;
}
