@use 'colors';

.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--focused, .mdc-text-field--invalid):is(
		.mdc-notched-outline__leading,
		.mdc-notched-outline__notch,
		.mdc-notched-outline__trailing
	) {
	border-color: colors.$border-inputs;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--invalid):is(.mdc-text-field--focused)
	:is(.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing) {
	border-color: colors.$primary-light-blue;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):is(.mdc-text-field--focused) {
	box-shadow: 0px 0px 8px rgba(0, 159, 227, 0.3);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):is(.mdc-text-field--invalid):is(.mdc-text-field--focused) {
	box-shadow: 0px 0px 8px rgba(255, 29, 29, 0.3);
}

mat-form-field:hover
	.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--focused, .mdc-text-field--invalid)
	:is(.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing) {
	border-color: colors.$border-inputs !important;
}
