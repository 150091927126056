@use 'material-design/index';
@use 'colors';
@import 'node_modules/swiper/swiper-bundle.min.css';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: Poppins, 'Helvetica Neue', sans-serif;
	font-size: 14px;
	caret-color: transparent;
	cursor: default;

	.form-item {
		display: flex;
		flex-direction: column;

		&__label {
			font-weight: 500;
			color: colors.$primary-medium-grey;
			align-self: flex-start;
		}
	}

	.link {
		text-decoration-line: underline;
		color: colors.$primary-light-blue;
		font-weight: 400;
		cursor: pointer;
		&:hover {
			font-weight: 500;
		}
	}

	.error-hint {
		font-size: 12px;
		font-weight: 500;
		color: colors.$error;
	}

	.mat-mdc-snack-bar-container {
		--mdc-snackbar-container-color: #ffffffeb;

		.mdc-snackbar__surface {
			margin-left: 2em;
			margin-bottom: 13em;

			border-radius: 18px !important;
			border: 1px solid #009fe3;
			.mat-mdc-snack-bar-label {
				padding: 0 !important;
			}
		}
	}

	.mat-mdc-snack-bar-container.warning {
		.mdc-snackbar__surface {
			margin-left: 2em;
			margin-bottom: 13em;

			border-radius: 18px !important;
			border: 1px solid #ff1d1d;
			.mat-mdc-snack-bar-label {
				padding: 0 !important;
			}
		}
	}

	.backdrop-background {
		background: rgba(0, 0, 0, 0.25) !important;
	}

	.cdk-overlay-container
		.ngx-mat-tel-input-overlay-backdrop
		+ .cdk-overlay-connected-position-bounding-box
		.cdk-overlay-pane
		.mat-mdc-menu-panel {
		max-height: calc(100vh - 70vh) !important;
	}

	.menu-notification {
		border-radius: 8px !important;
		border: 1px solid rgba(17, 17, 17, 0.32) !important;
		background: #fff;
		box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.12) !important;
	}

	.cart.mat-mdc-menu-panel {
		max-width: 376px;

		.mat-mdc-menu-content {
			padding: 8px 0px 0px 0px;
		}
	}

	.button-cart-menu {
		margin: 18px;
		border: 1px solid var(--Purple-PLUS, #4f32ff) !important;

		.mdc-button__label {
			display: flex !important;
			gap: 12px;
			color: var(--Dark-grey, #3c3c3b);
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.menu-post {
		--mat-menu-item-label-text-size: 14px;
	}

	.menu-user {
		margin-top: 10px;
		border-radius: 8px !important;
		box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.12) !important;
		font-size: 14px;

		.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
			font-size: 14px;
		}

		.mat-mdc-menu-content .mat-mdc-menu-item:hover {
			.mat-mdc-menu-item-text {
				color: colors.$primary-light-blue-80;
			}
			svg {
				color: colors.$primary-light-blue-80;
			}
		}
	}

	ngx-mat-intl-tel-input .ngx-mat-tel-input-container button {
		opacity: 1 !important;
	}

	.fus-option {
		&--empty {
			background-color: white !important;
			--mat-option-selected-state-layer-color: white;
		}
		// &:hover {
		// 	background-color: white !important;
		// }
		.mdc-list-item__primary-text {
			width: 100% !important;
			// --mat-option-selected-state-layer-color:
		}
		margin-bottom: 1em;

		.fus-card {
			display: flex;
			gap: 12px;
			app-avatar-circle img {
				box-shadow: none !important;
			}

			.fus-user {
				display: flex;
				flex-direction: column;
				flex: 1;
				color: #aaa;

				&__title {
					font-size: 14px;
					font-weight: 500;
				}
				&__sub-title {
					font-size: 12px;
					font-weight: 400;
				}
			}
		}

		.fus-empty-author {
			padding-top: 1.5em;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: #000;
			text-align: center;
			&__title {
				font-size: 14px;
				font-weight: 700;
			}
			&__subtitle {
				font-size: 12px;
				max-width: 18em;
			}
			&__button {
				margin-top: 10px;
			}
			&__list {
				padding-top: 0 !important;
			}
		}
	}
}

.input-post-content {
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
		border-color: #e7e7e7 !important;
	}
}

.input-post-content {
	.mat-mdc-text-field-wrapper {
		height: 52px;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #7b7a7a;
	}
}

.post-text-content {
	.mat-mdc-text-field-wrapper {
		height: 85px;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #92929d;
	}
}

.post-text-content {
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: transparent !important;
  }

	.mat-mdc-form-field-subscript-wrapper {
		margin-top: -20px;
	}

	.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
	.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
		height: 57px;
	}
}

.post-text-content {
	.mat-mdc-text-field-wrapper {
		height: 85px;
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #92929d;
	}
}

.post-text-content {
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
		border-color: transparent !important;
	}

	.mat-mdc-form-field-subscript-wrapper {
		margin-top: -20px;
	}

	.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
	.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
		height: 57px;
	}
}

.coment-textarea {
	.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
		height: 64.64px;
	}

	&-input {
		.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
		.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
			height: 64.64px;
		}
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
		border-color: #f1f1f5 !important;
	}

	.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
		border-top-left-radius: 0;
		border-top-right-radius: 15px !important;
		border-bottom-right-radius: 15px !important;
		border-bottom-left-radius: 0;
	}

	.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
		border-top-left-radius: 15px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 15px;
	}

	.mat-mdc-form-field-subscript-wrapper {
		margin-top: -25.6px;
	}

	.mat-mdc-text-field-wrapper {
		background: #fafafb;
	}
}

.search {
	.mat-mdc-text-field-wrapper {
		height: 50px !important;
		background: #f8f8f9;
		border-radius: 8px;
	}

	.mat-mdc-form-field-icon-prefix {
		padding: 0 8px 8px 8px !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
		border-color: #f8f8f9 !important;
	}

	.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
		border-top-left-radius: 0;
		border-top-right-radius: 8px !important;
		border-bottom-right-radius: 8px !important;
		border-bottom-left-radius: 0;
	}

	.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
		border-top-left-radius: 8px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 8px;
	}
}

.search {
	.mat-mdc-text-field-wrapper {
		height: 50px !important;
		background: #f8f8f9;
		border-radius: 8px;
	}

	.mat-mdc-form-field-icon-prefix {
		padding: 0 8px 8px 8px !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
		border-color: #f8f8f9 !important;
	}

	.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
		border-top-left-radius: 0;
		border-top-right-radius: 8px !important;
		border-bottom-right-radius: 8px !important;
		border-bottom-left-radius: 0;
	}

	.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
		border-top-left-radius: 8px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 8px;
	}
}

.subs-tabs.mat-mdc-tab.mdc-tab {
	width: 33% !important;
	height: 83px;
	justify-content: flex-start !important;
	padding-left: 31px !important;
	opacity: 0.3;
	background: #f7f7f7 !important;

	&--active {
		opacity: 1 !important;
	}
}

.subs-tabs.mat-mdc-tab:hover .mdc-tab__ripple::before {
	opacity: 0;
}

.subs-tabs.mat-mdc-tab:hover .mdc-tab__ripple::after {
	opacity: 0.5;
}

.subs-tabs_plus.mat-mdc-tab.mdc-tab {
	.mdc-tab-indicator {
		.mdc-tab-indicator__content--underline {
			border-top-width: 4px;
		}
		.mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
			border-color: var(--purple-plus, #4f32ff) !important;
		}
	}
}

.subs-tabs_free.mat-mdc-tab.mdc-tab {
	.mdc-tab-indicator {
		.mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
			border-color: var(--blue, #0069e3) !important;
		}

		.mdc-tab-indicator__content--underline {
			border-top-width: 4px;
		}
	}
}

.user-inf-personal.mat-expansion-panel {
	box-shadow: none !important;
	margin-top: 41px;

	.mat-expansion-panel-header {
		padding: 0 !important;
		margin: 0 24px;
		border-radius: 0px !important;
		border-bottom: 1px solid #eee;
		height: 25px;

		&:hover {
			background: none !important;
		}

		.mat-expansion-indicator {
			margin-right: 5px;
		}
	}
}

.chips_chip.mat-mdc-standard-chip {
	border: 1px solid #eeeeee;
	--mdc-chip-disabled-label-text-color: none !important;
	--mdc-chip-elevated-container-color: none !important;
	--mdc-chip-elevated-disabled-container-color: none !important;
	--mdc-chip-focus-state-layer-color: none !important;

	.mdc-evolution-chip__text-label {
		font-size: 12px !important;
	}
}

.menu-post.mat-mdc-menu-panel {
	position: relative;
	padding: 16px 0 0 0;
	max-width: 328px !important;
	box-shadow: none;
	background-color: transparent;
	top: -5px;

	.mat-mdc-menu-content {
		width: 310px;
		height: auto;
		border-radius: 5px;
		background: #fff;
		border: 1px solid #aaaaaac9;
	}
}

.confirmation-modal {
	.mdc-dialog__surface {
		border-radius: 16px !important;
	}
}

.default-check {
	display: flex;
	align-items: self-end;

	.mdc-form-field > label {
		padding-left: 10px;
	}

	.mdc-checkbox {
		padding: 0 !important;
		margin: 0 !important;
	}

	.mdc-checkbox .mdc-checkbox__background {
		top: 0 !important;
		left: 0 !important;
	}

	.mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
	.mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
	.mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
		border-color: #009fe3;
		background-color: #009fe3;

		.mdc-checkbox__mixedmark {
			border-color: #fff !important;
		}

		.mdc-checkbox__checkmark {
			color: #fff !important;
		}
	}

	.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
	.mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
	.mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
		border-color: #009fe3;
		background-color: #009fe3;

		.mdc-checkbox__mixedmark {
			border-color: #fff !important;
		}

		.mdc-checkbox__checkmark {
			color: #fff !important;
		}
	}

	.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background,
	.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate ~ .mdc-checkbox__background {
		border-color: #009fe3;
		background-color: #009fe3;

		.mdc-checkbox__mixedmark {
			border-color: #fff !important;
		}

		.mdc-checkbox__checkmark {
			color: #fff !important;
		}
	}

	.mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
	.mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
	.mdc-checkbox:not(:disabled):active
		.mdc-checkbox__native-control[data-indeterminate='true']:enabled
		~ .mdc-checkbox__background {
		border-color: #009fe3;
		background-color: #009fe3;

		.mdc-checkbox__mixedmark {
			border-color: #fff !important;
		}

		.mdc-checkbox__checkmark {
			color: #fff !important;
		}
	}

	.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
		color: #fff !important;
	}

	.mdc-checkbox
		.mdc-checkbox__native-control:enabled:focus:focus:not(:checked):not(:indeterminate)
		~ .mdc-checkbox__background {
		background-color: #fff !important;
	}
}

.afp-stepper {
	.mat-stepper-horizontal-line {
		min-width: 115px !important;
	}
}

.di-toggle.mat-mdc-slide-toggle.mat-accent {
	--mdc-switch-selected-focus-state-layer-color: #009fe3;
	--mdc-switch-selected-handle-color: #009fe3;
	--mdc-switch-selected-hover-state-layer-color: #009fe3;
	--mdc-switch-selected-pressed-state-layer-color: #009fe3;
	--mdc-switch-selected-focus-handle-color: #009fe3;
	--mdc-switch-selected-hover-handle-color: #009fe3;
	--mdc-switch-selected-pressed-handle-color: #009fe3;
	--mdc-switch-selected-focus-track-color: #e0e0e0;
	--mdc-switch-selected-hover-track-color: #e0e0e0;
	--mdc-switch-selected-pressed-track-color: #009fe3;
	--mdc-switch-selected-track-color: #009fe3;

	--mdc-switch-disabled-selected-handle-color: #aaaaaa;
	--mdc-switch-disabled-selected-icon-color: #aaaaaa;
	--mdc-switch-disabled-selected-track-color: #aaaaaa;
	--mdc-switch-disabled-unselected-handle-color: #aaaaaa;
	--mdc-switch-disabled-unselected-icon-color: #aaaaaa;
	--mdc-switch-disabled-unselected-track-color: #aaaaaa;
	--mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
	--mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	--mdc-switch-handle-shadow-color: black;
	--mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14),
		0px 0px 0px 0px rgba(0, 0, 0, 0.12);
	--mdc-switch-selected-icon-color: #aaaaaa;
	--mdc-switch-unselected-focus-handle-color: #aaaaaa;
	--mdc-switch-unselected-focus-state-layer-color: #aaaaaa;
	--mdc-switch-unselected-focus-track-color: #e0e0e0;
	--mdc-switch-unselected-handle-color: #aaaaaa;
	--mdc-switch-unselected-hover-handle-color: #aaaaaa;
	--mdc-switch-unselected-hover-state-layer-color: #aaaaaa;
	--mdc-switch-unselected-hover-track-color: #e0e0e0;
	--mdc-switch-unselected-icon-color: #aaaaaa;
	--mdc-switch-unselected-pressed-handle-color: #aaaaaa;
	--mdc-switch-unselected-pressed-state-layer-color: #aaaaaa;
	--mdc-switch-unselected-pressed-track-color: #e0e0e0;
	--mdc-switch-unselected-track-color: #e0e0e0;

	.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
		fill: #009fe3;
	}

	.mdc-switch:enabled .mdc-switch__track::after {
		background: #e5e5e5;
	}

	.mdc-switch:enabled .mdc-switch__track::before {
		background: #e5e5e5;
	}

	.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
		background: #009fe3;
	}
}

.di-checkbox {
	// .mdc-form-field{
	// 	.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked~.mdc-checkbox__background{
	// 		background-color: #009FE3;
	// 		border-color: #009FE3;
	// 	}
	// }
	--mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
	--mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
	--mdc-checkbox-selected-checkmark-color: #fff;
	--mdc-checkbox-selected-focus-icon-color: #009fe3;
	--mdc-checkbox-selected-hover-icon-color: #009fe3;
	--mdc-checkbox-selected-icon-color: #009fe3;
	--mdc-checkbox-selected-pressed-icon-color: #009fe3;
	--mdc-checkbox-unselected-focus-icon-color: #212121;
	--mdc-checkbox-unselected-hover-icon-color: #212121;
	--mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
	--mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
	--mdc-checkbox-selected-focus-state-layer-color: #009fe3;
	--mdc-checkbox-selected-hover-state-layer-color: #009fe3;
	--mdc-checkbox-selected-pressed-state-layer-color: #009fe3;
	--mdc-checkbox-unselected-focus-state-layer-color: white;
	--mdc-checkbox-unselected-hover-state-layer-color: white;
	--mdc-checkbox-unselected-pressed-state-layer-color: white;
}

/*SCROLL*/
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-track {
	background: rgb(179, 177, 177);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: rgb(136, 136, 136);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: rgb(100, 100, 100);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:active {
	background: rgb(68, 68, 68);
	border-radius: 10px;
}

.dark-mode {
  /* Ajustes generales para modo oscuro */
  background-color: #121212;
  color: #ffffff;

  /* Ajuste del <mat-form-field> con clase .coment-textarea */
  .coment-textarea {
    // Fondo oscuro interno
    .mat-mdc-text-field-wrapper {
      background-color: #333 !important;
    }

    // Borde
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-notched-outline__trailing {
      border-color: #555 !important; // Puedes ajustar el tono
    }

    // Texto del textarea
    .coment-textarea-input {
      color: #ffffff !important;    // Texto en blanco
      background-color: #333 !important;
    }
  }
}
